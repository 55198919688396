import React, {useState} from 'react'
import {Typography, Paper, useMediaQuery, Slide} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import JUB from '../Assets/TimelineFile/JUB.png'
import QM from '../Assets/TimelineFile/QM.png'
import ag_logo from '../Assets/TimelineFile/ag_logo.jpeg'
import logo_bdr from '../Assets/TimelineFile/logo_bdr.jpg'
import VizSensor from 'react-visibility-sensor'

const useStyles = makeStyles((theme) => ({
    heading:{
        fontFamily: 'Fira Sans Condensed',
        fontSize:"2.25rem",
        textAlign:"start",
        marginBottom:"2.5rem",
        marginLeft:"2.5rem",
        color:theme.palette.pink.main,
        [theme.breakpoints.down('xs')]:{
         fontSize: "1.75rem", 
       }
    },
    paper:{
        padding: '6px 16px',
        borderRadius:"15px",
        backgroundImage: 'linear-gradient(to bottom right,#FF8BA0,#E41F7B)',
        opacity:"75%",
        color:"black",
        [theme.breakpoints.down('xs')]:{
            padding: "10px", 
        }
    },
    dateFormat:{
        fontSize:"1rem",
        fontWeight:"700",
        color: theme.palette.pink.main,
        margin:"1rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: "0.75rem", 
        }
    },
    dateFormatSmall:{
        fontSize:"0.75rem",
        color: "black",
        margin:"1rem",
    },
    dot:{
        color:theme.palette.pink.dark,
    },
    connector:{
        height:"10rem",
        backgroundColor:theme.palette.pink.light,
    },
    title:{
        fontFamily:"Karla",
        fontSize:"1.5rem",
        fontWeight:"500",
        margin:"1rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: "1.2rem", 
          }
    },
    institute:{
        fontFamily:"Fira Sans Condensed",
        fontSize:"1.05rem",
        fontWeight:"300",
        margin:"1rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: "0.9rem", 
        }
    },
    timelineItem:{
        [theme.breakpoints.down('xs')]:{
            marginBottom: "0.5rem", 
        }
    }
   }));

function TimeLine() {

    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('xs'))

    let [active,setActive] = useState(false)

    return(
        <VizSensor partialVisibility minTopValue="400" onChange={(isVis)=>{setActive(isVis)}}>
            <div id="timeline" className="p-3">
                <Slide in={active} direction="left" timeout={2000}>
                    <Typography color="secondary" className={classes.heading}>Timeline</Typography>
                </Slide>
                <div className="d-flex">
                    <Timeline align={matches ? "left" : "alternate"}>
                    <Slide in={active} direction={matches? "down":"right"} timeout={2000}>
                            <TimelineItem className={classes.timelineItem}>
                                {matches ? <TimelineOppositeContent style={{ flex: 0 }}></TimelineOppositeContent> :
                                    <TimelineOppositeContent>
                                        <Typography className={classes.dateFormat}>
                                            April 2023 - Present
                                        </Typography>
                                    </TimelineOppositeContent>
                                }
                                <TimelineSeparator>
                                    <TimelineDot className={classes.dot}>
                                        <WorkIcon />
                                    </TimelineDot>
                                    <TimelineConnector className={classes.connector} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <img style={{ borderRadius: "15px"}} className="p-2 my-1" width="65" height="65" alt="" src={ag_logo}></img>
                                        <Typography className={classes.title}>
                                            Senior Frontend Engineer
                                        </Typography>
                                        <Typography className={classes.institute}>Amann Girrbach GmbH</Typography>
                                        {matches ? <Typography className={classes.dateFormatSmall}>April 2023 - Present</Typography> : <div></div>}
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </Slide>

                        <Slide in={active} direction={matches? "down":"left"} timeout={2000}>
                            <TimelineItem className={classes.timelineItem}>
                                {matches ? <TimelineOppositeContent style={{ flex: 0 }}></TimelineOppositeContent> :
                                    <TimelineOppositeContent>
                                        <Typography className={classes.dateFormat}>
                                            Sep 2021 - Nov 2022
                                        </Typography>
                                    </TimelineOppositeContent>
                                }
                                <TimelineSeparator>
                                    <TimelineDot className={classes.dot}>
                                        <WorkIcon />
                                    </TimelineDot>
                                    <TimelineConnector className={classes.connector} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <img style={{ borderRadius: "15px"}} className="p-2 my-1" width="65" height="65" alt="" src={QM}></img>
                                        <Typography className={classes.title}>
                                            Frontend Engineer
                                        </Typography>
                                        <Typography className={classes.institute}>Quality Match GmbH</Typography>
                                        {matches ? <Typography className={classes.dateFormatSmall}>Sep 2021 - Nov 2022</Typography> : <div></div>}
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </Slide>

                        <Slide in={active} timeout={2000} direction={matches? "down":"right"}>
                            <TimelineItem className={classes.timelineItem}>
                                {matches ? <TimelineOppositeContent style={{ flex: 0 }}></TimelineOppositeContent> :
                                    <TimelineOppositeContent>
                                        <Typography className={classes.dateFormat}>
                                            April 2020 - July 2021
                                        </Typography>
                                    </TimelineOppositeContent>
                                }
                                <TimelineSeparator>
                                    <TimelineDot className={classes.dot}>
                                        <WorkIcon />
                                    </TimelineDot>
                                    <TimelineConnector className={classes.connector} />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <img style={{ borderRadius: "15px" }} className="p-2 my-1" width="65" height="65" alt="" src={logo_bdr}></img>
                                        <Typography className={classes.title}>
                                            Frontend Developer
                                        </Typography>
                                        <Typography className={classes.institute}>Bundesdruckerei GmbH</Typography>
                                        {matches ? <Typography className={classes.dateFormatSmall}>April 2020 - July 2021</Typography> : <div></div>}
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </Slide>
                        <Slide in={active} timeout={2000} direction={matches? "down":"left"}>
                            <TimelineItem>
                                {matches ? <TimelineOppositeContent style={{ flex: 0 }}></TimelineOppositeContent> :
                                    <TimelineOppositeContent>
                                        <Typography className={classes.dateFormat}>
                                            Class of 2021
                                        </Typography>
                                    </TimelineOppositeContent>
                                }
                                <TimelineSeparator>
                                    <TimelineDot className={classes.dot}>
                                        <SchoolIcon />
                                    </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Paper elevation={3} className={classes.paper}>
                                        <img style={{ borderRadius: "15px" }} className="p-2 my-1" width="65" height="65" alt="" src={JUB}></img>
                                        <Typography className={classes.title}>
                                            B.Sc. Intelligent Mobile Systems
                                        </Typography>
                                        <Typography className={classes.institute}>Jacobs University Bremen</Typography>
                                        {matches ? <Typography className={classes.dateFormatSmall}>Class of 2021</Typography> : <div></div>}
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                        </Slide>
                    </Timeline>
                </div>
            </div>
        </VizSensor>
    )
}

export default TimeLine