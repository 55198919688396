import './App.css';
import { createTheme, ThemeProvider, useMediaQuery} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Navbar from './Components/Navbar'
import Home from './Components/Home'
import AboutMe from './Components/AboutMe';
import TechStack from './Components/TechStack';
import TimeLine from './Components/TimeLine';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import TimelineGif from './GifComponents/TimelineGif';
import TechGif from './GifComponents/TechGif';
import ProjectGif from './GifComponents/ProjectGif';

const Theme = createTheme({
  palette:{
    type: "dark",
    purple:{
      light:"#C060A1",
      main: "#BB86FC",
    },
    pink:{
      light:"#FF8BA0",
      main:"#E41F7B",
      dark:"#86003C",
    },
    green:{
      light:"#62A388",
      main: "#03DAC6",
      dark:"#055E68",
    },
    yellow:{
      main:"#F8C43A",
      dark:"#FF7517",
    },
  },
})

const App = () => {

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <ThemeProvider theme={Theme}>
      <div style={{backgroundColor: "#000000"}}>
        <Navbar/>
        <Home/>
        <div className={matches ? 'd-flex flex-column' : 'container d-flex flex-column'} >  
          <div className="my-5"><AboutMe/></div>

          <div className="my-5"><TimelineGif/></div>
          <div className="my-5"><TimeLine/></div>

          <div className="my-5"><TechGif/></div>
          <div className="my-5"><TechStack/></div>

          <div className="my-5"><ProjectGif/></div>
          <div className="my-5"><Projects/></div>

          <div className="mt-5"><Contact/></div>  
        </div>        
      </div>
    </ThemeProvider>
  );
}

export default App;
