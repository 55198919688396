import React,{useState} from 'react'
import {Fade, Zoom} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import projectBG from '../Assets/Images/projectBG.png';
import VizSensor from 'react-visibility-sensor'

const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
        flexDirection:"row",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${projectBG})`,
        color: theme.palette.yellow.dark,
        fontFamily: 'Oswald',
        textAlign: "center",
        alignSelf:"center",
        JustifySelf:"center",
        alignContent: "center",
        justifyContent: "center",
        fontSize: "3.5rem",
        [theme.breakpoints.down('sm')]:{
            fontSize: "2.25rem", 
       }
    },
    letter:{
        display:"flex",
        justifySelf:"center",
        alignSelf:"center",
    },
}));

function ProjectGif() {

    const classes = useStyles()

    let [active,setActive] = useState(false)

    return(
        <VizSensor partialVisibility minTopValue="400" onChange={(isVis) => { setActive(isVis) }}>
            <Fade in={active} timeout={2000}>
                <div className={classes.root}>
                    <Zoom in={active} timeout={750} style={{ transitionDelay: active ? "400ms" : "0ms" }}>
                        <div className={classes.letter}>Build.</div>
                    </Zoom>
                    <div className="mx-1"></div>
                    <Zoom in={active} timeout={750} style={{ transitionDelay: active ? "800ms" : "0ms" }}>
                        <div className={classes.letter}>Make Impact.</div>
                    </Zoom>
                </div>
            </Fade>
        </VizSensor>
    )
}

export default ProjectGif;