import React, {useState} from 'react'
import myImage from '../Assets/Images/myImage.jpg'
import {Grid, Typography, Button, Fade, Slide, Link, useMediaQuery} from '@material-ui/core'
import { makeStyles,useTheme } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import VizSensor from 'react-visibility-sensor'
import { LinkedIn, Instagram, GitHub } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   heading:{
       fontFamily: 'Fira Sans Condensed',
       fontSize:"2.25rem",
       textAlign:"start",
       color: theme.palette.purple.main,
       marginBottom:"2.5rem",
       marginLeft:"2.5rem",
       [theme.breakpoints.down('xs')]:{
        fontSize: "1.75rem", 
      }
   },
   button:{
       fontFamily:"Karla",
       fontSize:"1rem",
       borderRadius:"5em",
       justifySelf:"center",
       color: theme.palette.purple.main,
       borderColor: theme.palette.purple.light,
       margin:"1rem",
       [theme.breakpoints.down('xs')]:{
        fontSize: "0.85rem", 
      }
    },
    hi:{
        color: "white",
        marginRight:"0.6rem",
        textAlign:"start", 
        fontFamily:"Karla", 
        fontSize:"1.85rem",
        [theme.breakpoints.down('xs')]:{
            textAlign:"center",
            fontSize: "1.45rem", 
        }
   },
    name: {
        color: "white",
        textAlign: "start",
        fontFamily: "Karla",
        fontSize: "2rem",
        '&:hover':{
            color:theme.palette.purple.main,
            fontFamily: 'Fredoka One',
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: "center",
            fontSize: "1.45rem",
            '&:hover':{
                fontFamily: 'Fredoka One',
                color:theme.palette.purple.main,
            }, 
        }
    },
    position:{
        fontSize: "1.5rem",
        fontFamily: 'Fira Sans Condensed',
        justifyContent: "start",
        color: theme.palette.green.main,
        textAlign: "start",
        margin: "6px 0px",
        [theme.breakpoints.down('xs')]:{
         fontSize: "1.3rem", 
       }
    },
   aboutMeText:{
       fontSize: "1.35rem",
       fontFamily: 'Lato',
       color:theme.palette.purple.main,
       margin: "1.5rem",
       [theme.breakpoints.down('xs')]:{
        fontSize: "1.1rem", 
      }
   },
   buttonContainer: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 12,
   },
}));

function AboutMe(){

    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    let [active,setActive] = useState(false)

    return(
        <VizSensor partialVisibility minTopValue="400" onChange={(isVis) => {setActive(isVis)}}>
            <div id="aboutMe" className="p-3">
                <Slide in={active} direction="left" timeout={2000}>
                    <Typography className={classes.heading}>About</Typography>
                </Slide>
                <Grid container spacing={2} justifyContent="space-evenly" alignItems="center" style={{ textAlign: "center" }}>
                    <Grid item xs={12} md={4}>
                        <Slide in={active} direction="right" timeout={2000}>
                            <img alt="" src={myImage} width="300" height="375" style={{ borderRadius: "20px" }}></img>
                        </Slide>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <div className="d-flex flex-column">
                            <Fade in={active} style={{ transitionDelay: active ? '500ms' : '0ms' }}>
                                <div className="d-flex flex-row">
                                    <div className={classes.hi}>Hi, I'm </div>
                                    <div className={classes.name}>A</div>
                                    <div className={classes.name}>l</div>
                                    <div className={classes.name}>i</div>
                                    <div className={classes.name}>a</div>
                                    <div className={classes.name}>s</div>
                                    <div className={classes.name}>g</div>
                                    <div className={classes.name}>a</div>
                                    <div className={classes.name}>r</div>
                                    <div className="mx-1"> </div>
                                    <div className={classes.name}>B</div>
                                    <div className={classes.name}>a</div>
                                    <div className={classes.name}>n</div>
                                    <div className={classes.name}>d</div>
                                    <div className={classes.name}>u</div>
                                    <div className={classes.name}>k</div>
                                    <div className={classes.name}>w</div>
                                    <div className={classes.name}>a</div>
                                    <div className={classes.name}>l</div>
                                    <div className={classes.name}>a</div>
                                </div>  
                            </Fade>
                            <Fade in={active} style={{ transitionDelay: active ? '1000ms' : '0ms' }}>
                                <div className={classes.position}>
                                    SENIOR FRONTEND ENGINEER/ARCHITECT 
                                </div>
                            </Fade>
                            <Fade in={active} style={{ transitionDelay: active ? '1500ms' : '0ms' }}>
                                <div className={classes.aboutMeText}>
                                    I am a tech savvy frontend developer who loves building high quality User Experiences with high quality code by 
                                    following the basics like Atomic design and SOLID principles. Building  digital solutions to real world 
                                    problems is my hobby, passion and bread-and-butter. Apart from tech, I also love Photography, DJing and Geo-politics.
                                    If you like any of these topics or even any other interesting topics, lets connect! 
                                </div>
                            </Fade>
                            
                        </div>
                    </Grid>

                    <div className={classes.buttonContainer} >
                        <Link style={{marginRight: 40}} href="https://www.linkedin.com/in/aliasgar-bandukwala-292067164/" target="_blank"><LinkedIn style={{width:matches?"30px":"40px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
                        <Link style={{marginRight: 40}} href="https://github.com/AliBandukwala" target="_blank"><GitHub style={{width:matches?"26px":"36px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
                        <Link style={{marginRight: 20}} href="https://www.instagram.com/alibandukwala_/" target="_blank"><Instagram style={{width:matches?"30px":"40px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
                        <a style={{ textDecoration: "none" }} href="/Resume/Ali-Bandukwala_Resume.pdf" download="Aliasgar_Bandukwala_Resume.pdf">
                            <Button variant="outlined" className={classes.button} startIcon={<GetAppIcon />}>Download CV </Button>
                        </a>
                    </div>
                                   
                </Grid>
            </div>
        </VizSensor>
    )
}

export default AboutMe