import React, { useState } from 'react'
import {Button, TextField, Typography, Link, useMediaQuery, Snackbar} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
//import { LinkedIn, GitHub, Instagram, Twitter } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    heading:{
        color: theme.palette.pink.main,
        fontFamily: 'Fira Sans Condensed',
        fontSize:"2.25rem",
        textAlign:"center",
        marginBottom:"1rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: "1.75rem", 
        }
    },
    sendingMsg:{
        fontFamily:"Karla",
        fontSize:"1.25rem",
        color: theme.palette.yellow.main,
    },
    emailLink:{
        fontFamily:"Fira Sans Condensed",
        fontSize:"1.25rem",
    },
    input:{
        borderColor: theme.palette.pink.main,
        "&.Mui-focused": {
            border: "2px solid",
            borderColor:theme.palette.purple.main,
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        }
    },
    inputLabelProps:{
        color: theme.palette.green.main,
        "&.Mui-focused": {
            fontSize: "1.5rem",
            color:theme.palette.green.dark,
        }
    },
    button:{
        color: theme.palette.yellow.dark,
        borderColor: theme.palette.yellow.main,
    },
    icon:{
        color: theme.palette.purple.main,
    },
}));

function Contact() {

    const classes = useStyles()
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('sm'))

    const [sending, setSending] = useState(false)
    const [detailTxt, setDetails] = useState({name:"",email:"",subject:"",message:""})
    const [openSB, setOpenSB] = useState(false)
    const [errEmail, setErrEmail] = useState(false);
    const [errName, setErrName] = useState(false);
    const [errSub, setErrSub] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [err, setErr] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSB(false);
    };

    const handleChange = (e) => {
        setDetails({
            ...detailTxt,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrName(false)
        setErrEmail(false)
        setErrSub(false)
        setErrMsg(false)
        if(detailTxt.name === ''){setErrName(true)}
        else if(detailTxt.email === ''){setErrEmail(true)}
        else if(detailTxt.subject === ''){setErrSub(true)}
        else if(detailTxt.message === ''){setErrMsg(true)}
        else if(detailTxt.name !== '' && detailTxt.email !== '' && detailTxt.subject !== '' && detailTxt.message !== ''){
        
        setSending(true)
        let details = {
          name: detailTxt.name,
          email: detailTxt.email,
          subject: detailTxt.subject,
          message: detailTxt.message,
        };
        let response = await fetch("https://alibandukwala-contact-server.herokuapp.com/contact", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(details),
        });
        let result = await response.json();
        switch(result.status){
            case "ERROR":
                setSending(false)
                setErr(true)
                setOpenSB(true)
                break;
            case "Message Sent":
                setSending(false)
                setOpenSB(true)
                break;
            default:
                break;
        }
        }
    };

    let sbMsg = err ? "Task Failed. Please email to bandukwalaali99@gmail.com" : "Message Sent Successfully";
    let emailLink = "mailto:bandukwalaali99@gmail.com?subject="+detailTxt.subject+"&body="+detailTxt.message;

    let contactUI = sending ?
        (
            <div style={{height:"300px"}} className="d-flex flex-column justify-content-around align-items-center w-100">
                <Typography className={classes.sendingMsg}>Server seems to be down. Please use the email below</Typography>
                <Link className={classes.emailLink} href={emailLink} target="_blank">bandukwalaali99@gmail.com</Link>  
            </div>
        ) :
        (
            <div className={matches ? "container" : "container w-50"}>
                <TextField onChange={handleChange} error={errEmail} helperText={errEmail? "Email cannot be empty":""}
                    InputProps={{ className: classes.input }} InputLabelProps={{ className: classes.inputLabelProps }}
                    fullWidth margin="normal" name="email" label="Your Email" variant="outlined" />

                <div className="d-flex flex-row justify-content-between">
                    <TextField onChange={handleChange} error={errName} helperText={errName? "Name cannot be empty":""}
                        InputProps={{ className: classes.input }} InputLabelProps={{ className: classes.inputLabelProps }}
                        fullWidth margin="normal" name="name" label="Your Name" variant="outlined" style={{ marginRight: "10px" }} />

                    <TextField onChange={handleChange} error={errSub} helperText={errSub? "Subject cannot be empty":""}
                        InputProps={{ className: classes.input }} InputLabelProps={{ className: classes.inputLabelProps }}
                        fullWidth margin="normal" name="subject" label="Subject" variant="outlined" />
                </div>

                <TextField onChange={handleChange} error={errMsg} helperText={errMsg? "Message cannot be empty":""}
                    InputProps={{ className: classes.input }} InputLabelProps={{ className: classes.inputLabelProps }}
                    fullWidth margin="normal" name="message" label="Your Message" multiline rows={4} variant="outlined" />
            </div>
        )

    return(
        <div style={{marginBottom:36}} id="contact" className="d-flex flex-column align-items-center">

            <Typography color="secondary" className={classes.heading}>Get In Touch</Typography>

            {contactUI}

            <Button disabled={sending} onClick={handleSubmit} style={{ alignSelf: "center", borderRadius: "5em", marginTop: "1em" }} variant="outlined" className={classes.button} endIcon={<EmailIcon />}>Send Message</Button>

            {/*
            <div className={matches?"d-flex flex-row container justify-content-around":"d-flex flex-row container w-50 justify-content-around"} style={{margin:"3em", marginTop:matches?"3em":"5em"}}>
                <Link href="https://github.com/AliBandukwala" target="_blank"><GitHub style={{width:matches?"30px":"40px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
                <Link href="https://www.linkedin.com/in/aliasgar-bandukwala-292067164/" target="_blank"><LinkedIn style={{width:matches?"30px":"40px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
                <Link href="https://www.instagram.com/alibandukwala_/" target="_blank"><Instagram style={{width:matches?"30px":"40px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
                <Link href="https://twitter.com/alibandukwala__" target="_blank"><Twitter style={{width:matches?"30px":"40px", height:matches?"30px":"40px"}} className={classes.icon}/></Link>
            </div>
            */}

            <Snackbar open={openSB} onClose={handleClose} autoHideDuration={4000}>
                <Alert onClose={handleClose} severity={err?"error":"success"}>
                    {sbMsg}
                </Alert>
            </Snackbar>

        </div>
    )
}

export default Contact

/* sending gif:
<img alt='' src={spinner} className="justify-self-center align-self-center"></img>
 */