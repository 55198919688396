import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import logo from '../Assets/Images/logo.png'
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-scroll'


const useStyles = makeStyles((theme) => ({
  AppBar: {
    height: "60px",
    padding: "0px",
    elevation: "0",
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  logo: {
    width:"37px",
    height:"26px",
  },
  link: { 
    fontFamily: "Karla",
    fontSize: "1.25rem",
    color: theme.palette.primary.main,   
  },
}));

const Navbar = () => {

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className={classes.AppBar}>
      <Toolbar className="d-flex flex-row justify-content-between">

        <IconButton>
          <Link to="home" smooth duration={1000}>
            <img alt="" className={classes.logo} src={logo}></img>
          </Link>
        </IconButton>

        <div>
          <IconButton
            className={classes.menuIcon}
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            edge="end"
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: '20ch',
              },
            }}
          >
            <MenuItem style={{color:"#BB86FC", fontFamily:"Karla", fontSize:"1.25rem"}} onClick={handleClose}><Link to='aboutMe' smooth duration={500}>About Me</Link></MenuItem>
            <MenuItem style={{color:"#E41F7B", fontFamily:"Karla", fontSize:"1.25rem"}} onClick={handleClose}><Link to='timeline' smooth duration={500}>Timeline</Link></MenuItem>
            <MenuItem style={{color:"#03DAC6", fontFamily:"Karla", fontSize:"1.25rem"}} onClick={handleClose}><Link to='techStack' smooth duration={500}>Tech stack</Link></MenuItem>
            <MenuItem style={{color:"#FF7517", fontFamily:"Karla", fontSize:"1.25rem"}} onClick={handleClose}><Link to='projects' smooth duration={500}>Projects</Link></MenuItem>
            <MenuItem onClick={handleClose}>
              <Link to='contact' smooth duration={500}>
                <Button style={{borderColor:"#BB86FC", color:"#BB86FC", fontFamily:"Karla", fontSize:"1rem"}} variant="outlined">Contact</Button>
              </Link>
            </MenuItem>
          </Menu>
        </div>  
      </Toolbar>
    </AppBar>
  );
}

export default Navbar