import React, {useState} from 'react'
import {Grid, Typography, Chip, Avatar, 
    Card, CardActionArea, CardActions, CardContent, CardMedia,
    Accordion, AccordionSummary, AccordionDetails, Link, Grow, Slide} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import LinkIcon from '@material-ui/icons/Link';
import {GitHub, ExpandMore } from '@material-ui/icons';
import sensorData from '../Assets/ProjectsImgs/sensor-data.png'
import hari from '../Assets/ProjectsImgs/hari.png'
import bdrImg1 from '../Assets/ProjectsImgs/bdrImg1.png'
import DietrixLogo from '../Assets/ProjectsImgs/DietrixLogo.png'
import weatherImg1 from '../Assets/ProjectsImgs/weatherImg1.png'
import react from '../Assets/Images/react.png'
import redux from '../Assets/Images/redux.png'
import angular from '../Assets/Images/angular.png'
import MUI from '../Assets/Images/MUI.png'
import android from '../Assets/Images/android.png'
import firebase from '../Assets/Images/firebase.png'
import sql from '../Assets/Images/sql.png'
import flutter from '../Assets/Images/flutter.png'
import buzzinger_logo from '../Assets/ProjectsImgs/forum_logo.jpg'
import VizSensor from 'react-visibility-sensor'

const useStyles = makeStyles((theme) => ({
    heading:{
        fontFamily: 'Fira Sans Condensed',
        fontSize:"2.25rem",
        textAlign:"start",
        color: theme.palette.yellow.main,
        marginBottom:"2.5rem",
        marginLeft:"2.5rem",
        [theme.breakpoints.down('xs')]:{
         fontSize: "1.75rem", 
       }
    },
    root: {   
        borderRadius:"10px",
        border:"2px solid",
        borderColor: "black",
        '&:hover':{
            borderColor: theme.palette.yellow.main,
        },
    },
    accordion:{
        elevation:'0',
        border:"none",
        boxShadow:"none",
        margin:0,
    },
    actionArea:{
        opacity:"50%",
        '&:hover':{
            opacity:"100%",
        },
        [theme.breakpoints.down('sm')]:{
            opacity:"100%", 
        }
    },
    media: {
        height: "180px",  
    },
    title:{
        fontFamily:"Karla",
        fontSize:"1.25rem",
        fontWeight:"500",
        [theme.breakpoints.down('xs')]:{
            fontSize: "1.2rem",
          }
    },
    description:{
        fontSize:"0.85rem",    
        [theme.breakpoints.down('xs')]:{
            fontSize: "0.75rem",
        }
    },
    icon:{
        color: theme.palette.yellow.dark,
    },
}));

function Projects() {

    const classes = useStyles()

    let [active,setActive] = useState(false)

    return(
        <VizSensor partialVisibility minTopValue="400" onChange={(isVis)=>{setActive(isVis)}}>
            <div id="projects" className="p-3">
                <Slide in={active} direction="left" timeout={2000}>
                    <Typography className={classes.heading}>Projects</Typography>
                </Slide>

                <Grid container spacing={4} alignItems="center" justifyContent="center">

                <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grow in={active} timeout={1500} style={{ transitionDelay: active ? '250ms' : '0ms' }}>
                            <Card className={classes.root}>
                                <CardActionArea className={classes.actionArea}>
                                    <CardMedia
                                        className={classes.media}
                                        image={buzzinger_logo}
                                        title="Buzzinger"
                                    />
                                    <CardContent>
                                        <Accordion className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography className={classes.title} gutterBottom>
                                                    Buzzinger
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.description}>
                                                    Stealth
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Grid container spacing={1}>
                                            <Grid item><Chip label="Flutter" variant="outlined" size="small" avatar={<Avatar alt="" src={flutter} />} /></Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ justifyContent: "flex-end" }}>
                                    <Link href="https://play.google.com/store/apps/details?id=com.app.buzzinger" target="_blank"><LinkIcon size="small" className={classes.icon} /></Link>
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>

                <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grow in={active} timeout={1500} style={{ transitionDelay: active ? '250ms' : '0ms' }}>
                            <Card className={classes.root}>
                                <CardActionArea className={classes.actionArea}>
                                    <CardMedia
                                        className={classes.media}
                                        image={hari}
                                        title="HARI"
                                    />
                                    <CardContent>
                                        <Accordion className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography className={classes.title} gutterBottom>
                                                    HARI
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.description}>
                                                   HARI- HIGHLY ACTIONABLE REAL-TIME INSIGHTS. HARI manages all aspects of the dataset creation and verification process. The platform allows data scientists and machine learning professionals to gain real-time actionable insights from their vision-based datasets.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Grid container spacing={1}>
                                            <Grid item><Chip label="React" variant="outlined" size="small" avatar={<Avatar alt="" src={react} />} /></Grid>
                                            <Grid item><Chip label="Redux" variant="outlined" size="small" avatar={<Avatar alt="" src={redux} />} /></Grid>
                                            <Grid item><Chip label="Material UI" variant="outlined" size="small" avatar={<Avatar alt="" src={MUI} />} /></Grid>
                                            
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ justifyContent: "flex-end" }}>
                                    <Link href="https://www.quality-match.com/hari" target="_blank"><LinkIcon size="small" className={classes.icon} /></Link>
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>

                <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grow in={active} timeout={1500} style={{ transitionDelay: active ? '250ms' : '0ms' }}>
                            <Card className={classes.root}>
                                <CardActionArea className={classes.actionArea}>
                                    <CardMedia
                                        className={classes.media}
                                        image={sensorData}
                                        title="SensorsData"
                                    />
                                    <CardContent>
                                        <Accordion className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography className={classes.title} gutterBottom>
                                                   Sensors Data
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.description}>
                                                   A web-app to get and display data for different sensors in different industries.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Grid container spacing={1}>
                                            <Grid item><Chip label="React" variant="outlined" size="small" avatar={<Avatar alt="" src={react} />} /></Grid>
                                            <Grid item><Chip label="Material UI" variant="outlined" size="small" avatar={<Avatar alt="" src={MUI} />} /></Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ justifyContent: "flex-end" }}>
                                    <Link href="https://github.com/AliBandukwala/Sensor-Dashboard" target="_blank"><GitHub size="small" className={classes.icon} /></Link>
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grow in={active} timeout={1500}>
                            <Card className={classes.root} >
                                <CardActionArea className={classes.actionArea}>
                                    <CardMedia
                                        className={classes.media}
                                        image={bdrImg1}
                                        title="Fingerprint acquisition software for Bundesdruckerei GmbH"
                                    />
                                    <CardContent>
                                        <Accordion className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography className={classes.title} gutterBottom>
                                                    Fingerprint Acquisition
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.description}>
                                                    Developed this software for Bundesdruckerei GmbH for acquiring fingerprints of visa and residence permit appplicants in immigration offices across Germany.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Grid container spacing={1}>
                                            <Grid item><Chip label="Angular" variant="outlined" size="small" avatar={<Avatar alt="" src={angular} />} /></Grid>
                                            <Grid item><Chip label="Material UI" variant="outlined" size="small" avatar={<Avatar alt="" src={MUI} />} /></Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ justifyContent: "flex-end" }}>
                                    {/*<Link href="https://github.com/AliBandukwala/BDR-fingerprint" target="_blank"><GitHub size="small" className={classes.icon} /></Link>*/}
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grow in={active} timeout={1500} style={{ transitionDelay: active ? '500ms' : '0ms' }}>
                            <Card className={classes.root}>
                                <CardActionArea className={classes.actionArea}>
                                    <CardMedia
                                        className={classes.media}
                                        image={weatherImg1}
                                        title="Weather History web-app for Quality Match GmbH"
                                    />
                                    <CardContent>
                                        <Accordion className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography className={classes.title} gutterBottom>
                                                    Weather History
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.description}>
                                                    Developed as an application project for Quality match GmbH, this web-app show weather history like temperature and wind speed for 5 different German cities in a line chart.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Grid container spacing={1}>
                                            <Grid item><Chip label="React" variant="outlined" size="small" avatar={<Avatar alt="" src={react} />} /></Grid>
                                            <Grid item><Chip label="Material UI" variant="outlined" size="small" avatar={<Avatar alt="" src={MUI} />} /></Grid>
                                            <Grid item><Chip label="Firebase" variant="outlined" size="small" avatar={<Avatar alt="" src={firebase} />} /></Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ justifyContent: "flex-end" }}>
                                    <Link href="https://github.com/AliBandukwala/HistoricalWeather-React" target="_blank"><GitHub size="small" className={classes.icon} /></Link>
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>

                    <Grid item xs={12} sm={8} md={6} lg={4}>
                        <Grow in={active} timeout={1500} style={{ transitionDelay: active ? '750ms' : '0ms' }}>
                            <Card className={classes.root}>
                                <CardActionArea className={classes.actionArea}>
                                    <CardMedia
                                        className={classes.media}
                                        image={DietrixLogo}
                                        title="Dietrix Android app for dietitians/nutritionists"
                                    />
                                    <CardContent>
                                        <Accordion className={classes.accordion}>
                                            <AccordionSummary expandIcon={<ExpandMore />}>
                                                <Typography className={classes.title} gutterBottom>
                                                    Dietrix
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography className={classes.description}>
                                                    Developed this native android app for dietitians/nutritionists to help them with their work like keep clients' payment and weight records, send digital charts and more.
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                        <Grid container spacing={1}>
                                            <Grid item><Chip label="Android" variant="outlined" size="small" avatar={<Avatar alt="" src={android} />} /></Grid>
                                            <Grid item><Chip label="SQL" variant="outlined" size="small" avatar={<Avatar alt="" src={sql} />} /></Grid>
                                        </Grid>
                                    </CardContent>
                                </CardActionArea>
                                <CardActions style={{ justifyContent: "flex-end" }}>
                                    <Link href="https://play.google.com/store/apps/details?id=dietrixapp.dietrix" target="_blank"><LinkIcon style={{marginRight:"0.5rem"}} className={classes.icon} size="small" /></Link>
                                    <Link href="https://github.com/AliBandukwala/Dietrix-Android" target="_blank"><GitHub size="small" className={classes.icon} /></Link>
                                </CardActions>
                            </Card>
                        </Grow>
                    </Grid>

                </Grid>

            </div>
        </VizSensor>
    )
}

export default Projects