import React, {useState} from 'react'
import {Slide} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import HomePosterDark from '../Assets/Images/HomePosterDark.gif';
import VizSensor from 'react-visibility-sensor'

const useStyles = makeStyles((theme) => ({
    root:{
        display:"flex",
        flexDirection:"row",
        height: "100vh",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "white",
        backgroundImage: `url(${HomePosterDark})`,
        fontFamily: 'Fredoka One',
        textAlign: "center",
        alignSelf:"center",
        JustifySelf:"center",
        alignContent: "center",
        justifyContent: "center",
        fontSize: "3.5rem",
        [theme.breakpoints.down('xs')]:{
            fontSize: "2.25rem", 
       }
    },
    letter:{
        display:"flex",
        justifySelf:"center",
        alignSelf:"center",
        '&:hover':{
            color:theme.palette.primary.main,
            fontFamily: 'Fira Sans Condensed',
        },
        [theme.breakpoints.down('xs')]:{
            '&:hover':{
                fontFamily: 'Fira Sans Condensed',
                color:theme.palette.primary.main,
            }, 
       }
    },
}));

function Home() {
    const classes = useStyles()

    let [active,setActive] = useState(false)

    return(
        <VizSensor partialVisibility onChange={(isVis) => { setActive(isVis) }}>
            <div className={classes.root} id="home">
                <Slide in={active} direction="right" style={{ transitionDelay: active? "150ms":"0ms" }}><div className={classes.letter}>D</div></Slide>
                <Slide in={active} direction="right" style={{ transitionDelay: active? "300ms":"0ms" }}><div className={classes.letter}>e</div></Slide>
                <Slide in={active} direction="right" style={{ transitionDelay: active ? "450ms":"0ms" }}><div className={classes.letter}>s</div></Slide>
                <Slide in={active} direction="right" style={{ transitionDelay: active ?"600ms":"0ms" }}><div className={classes.letter}>i</div></Slide>
                <Slide in={active} direction="right" style={{ transitionDelay: active ?"750ms":"0ms" }}><div className={classes.letter}>g</div></Slide>
                <Slide in={active} direction="right" style={{ transitionDelay: active ?"900ms":"0ms" }}><div className={classes.letter}>n</div></Slide>
                <div className={classes.letter}>.</div>
                <Slide in={active} direction="left" style={{ transitionDelay: active? "150ms":"0ms" }}><div className={classes.letter}>D</div></Slide>
                <Slide in={active} direction="left" style={{ transitionDelay: active ?"300":"0ms" }}><div className={classes.letter}>e</div></Slide>
                <Slide in={active} direction="left" style={{ transitionDelay: active ?"450ms":"0ms" }}><div className={classes.letter}>v</div></Slide>
                <Slide in={active} direction="left" style={{ transitionDelay: active ?"600ms":"0ms" }}><div className={classes.letter}>e</div></Slide>
                <Slide in={active} direction="left" style={{ transitionDelay: active ?"750ms":"0ms" }}><div className={classes.letter}>l</div></Slide>
                <Slide in={active} direction="left" style={{ transitionDelay: active ?"900ms":"0ms" }}><div className={classes.letter}>o</div></Slide>
                <Slide in={active} direction="left" style={{ transitionDelay: active ?"1050ms":"0ms" }}><div className={classes.letter}>p</div></Slide>
                <div className={classes.letter}>.</div>
            </div>
        </VizSensor>
    )
}

export default Home;