import React, {useState} from 'react'
import {Grid,Typography,Chip,Avatar, Slide, Collapse} from '@material-ui/core'
import htmlImg from '../Assets/Images/htmlImg.png'
import css from '../Assets/Images/css.jpg'
import JS from '../Assets/Images/JS.png'
import TS from '../Assets/Images/ts.png'
import dart from '../Assets/Images/dart.png'
import react from '../Assets/Images/react.png'
import cypress from '../Assets/Images/cypress.jpg'
import next from '../Assets/Images/next.png'
import angular from '../Assets/Images/angular.png'
import bootstrap from '../Assets/Images/bootstrap.jpg'
import MUI from '../Assets/Images/MUI.png'
import vue from '../Assets/Images/vue.png'
import singleSpa from '../Assets/Images/singleSpa.png'
import ModFed from '../Assets/Images/mod-fed.jpg'
import greensock from '../Assets/Images/greensock.png'
import tailwind from '../Assets/Images/tailwind.png'
import figma from '../Assets/Images/figma.png'
import reactNative from '../Assets/Images/reactNative.png'
import flutter from '../Assets/Images/flutter.png'
import firebase from '../Assets/Images/firebase.png'
import git from '../Assets/Images/git.png'
import devTools from '../Assets/Images/devTools.jpg'
import jira from '../Assets/Images/jira.png'
import jest from '../Assets/Images/jest.png'
import { makeStyles } from '@material-ui/core/styles';
import VizSensor from 'react-visibility-sensor'

const useStyles = makeStyles((theme) => ({
    heading:{
        fontFamily: 'Fira Sans Condensed',
        fontSize:"2.25rem",
        textAlign:"start",
        color: theme.palette.green.main,
        marginBottom:"2.5rem",
        marginLeft:"2.5rem",
        [theme.breakpoints.down('xs')]:{
         fontSize: "1.75rem", 
       }
    },
   stackHeadings:{
    fontSize: "1.75rem",
    margin: "1rem",
    fontWeight: "600",
    fontFamily: 'Karla',
    textAlign: "center",
    color: "white",
    [theme.breakpoints.down('xs')]:{
        fontSize: "1.25rem", 
      }
   },
   chips:{
       fontFamily:"Karla",
       fontSize:"1rem",
       fontWeight:"100",
       backgroundColor: theme.palette.green.dark,
   }
  }));

function TechStack(){

    const classes = useStyles()

    let [active,setActive] = useState(false)

    return(
        <VizSensor partialVisibility minTopValue="400" onChange={(isVis)=>{setActive(isVis)}}>
            <div id="techStack" className="p-3">
                <Slide in={active} direction="left" timeout={2000}>
                    <Typography className={classes.heading}>Tech Stack</Typography>
                </Slide>
                <Grid container style={{ marginBottom: "1rem" }} spacing={3} justifyContent="space-evenly" alignItems="center">

                    <Grid item xs={12} md={5}>
                        <Typography className={classes.stackHeadings}>Frameworks</Typography>
                        <Collapse timeout={1500} in={active}>    
                            <Grid container spacing={3} justifyContent="space-evenly" alignItems="center" style={{ textAlign: "center" }}>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="React" avatar={<Avatar alt="" src={react} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Next" avatar={<Avatar alt="" src={next} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Angular" avatar={<Avatar alt="" src={angular} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Vue" avatar={<Avatar alt="" src={vue} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="React Native" avatar={<Avatar alt="" src={reactNative} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Flutter" avatar={<Avatar alt="" src={flutter} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Module Fed." avatar={<Avatar alt="" src={ModFed} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Single-SPA" avatar={<Avatar alt="" src={singleSpa} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Firebase" avatar={<Avatar alt="" src={firebase} />} /></Grid>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Typography className={classes.stackHeadings}>UI/Design</Typography>
                        <Collapse timeout={1500} in={active} style={{ transitionDelay: active ? '0ms' : '0ms' }}>    
                            <Grid container spacing={3} justifyContent="space-evenly" alignItems="center" style={{ textAlign: "center" }}>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Material UI" avatar={<Avatar alt="" src={MUI} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="TailwindCSS" avatar={<Avatar alt="" src={tailwind} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="GreenSock" avatar={<Avatar alt="" src={greensock} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Figma" avatar={<Avatar alt="" src={figma} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Bootstrap" avatar={<Avatar alt="" src={bootstrap} />} /></Grid>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Typography className={classes.stackHeadings}>Languages</Typography>
                        <Collapse timeout={1500} in={active} style={{ transitionDelay: active ? '0ms' : '0ms' }}>    
                            <Grid container spacing={3} justifyContent="space-evenly" alignItems="center" style={{ textAlign: "center" }}>
                            <Grid item xs={6} md={4}><Chip className={classes.chips} label="HTML" avatar={<Avatar alt="" src={htmlImg} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="CSS" avatar={<Avatar alt="" src={css} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="JavaScript" avatar={<Avatar alt="" src={JS} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="TypeScript" avatar={<Avatar alt="" src={TS} />} /></Grid>
                                <Grid item xs={6} md={4}><Chip className={classes.chips} label="Dart" avatar={<Avatar alt="" src={dart} />} /></Grid>
                            </Grid>
                        </Collapse>
                    </Grid>

                    <Grid item xs={12} md={5}>
                        <Typography className={classes.stackHeadings}>Testing/Tools</Typography>
                        <Collapse timeout={1500} in={active} style={{ transitionDelay: active ? '0ms' : '0ms' }}>    
                            <Grid container spacing={3} justifyContent="space-evenly" alignItems="center" style={{ textAlign: "center" }}>
                                <Grid item xs={6} md={4} lg={3}><Chip className={classes.chips} label="Git" avatar={<Avatar alt="" src={git} />} /></Grid>
                                <Grid item xs={6} md={4} lg={3}><Chip className={classes.chips} label="Jest" avatar={<Avatar alt="" src={jest} />} /></Grid>
                                <Grid item xs={6} md={4} lg={3}><Chip className={classes.chips} label="Cypress" avatar={<Avatar alt="" src={cypress} />} /></Grid>
                                <Grid item xs={6} md={4} lg={3}><Chip className={classes.chips} label="Dev Tools" avatar={<Avatar alt="" src={devTools} />} /></Grid>
                                <Grid item xs={6} md={4} lg={3}><Chip className={classes.chips} label="Jira" avatar={<Avatar alt="" src={jira} />} /></Grid>             
                            </Grid>
                        </Collapse>
                    </Grid>

                </Grid>

            </div>
        </VizSensor>
    )
}

export default TechStack


                

                